import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
// @mui
import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import FormProvider, { RHFTextField } from "../../components/hook-form";
import { useSnackbar } from "../../components/snackbar";
// hooks
import useResponsive from "../../hooks/useResponsive";

// ----------------------------------------------------------------------

export default function HomeSignupForm({ colorMode = "dark", onSuccess }) {
  const theme = useTheme();
  const isDesktop = useResponsive("up", "md");
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState({ submitting: false });

  const ContactFormSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
  });

  const defaultValues = useMemo(
    () => ({
      email: "",
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(ContactFormSchema),
    defaultValues,
  });

  const { reset, handleSubmit } = methods;

  const onSubmit = async (formData) => {
    setState({ ...state, submitting: true });
    // eslint-disable-next-line no-undef
    grecaptcha.ready(() => {
      // eslint-disable-next-line no-undef
      grecaptcha
        .execute("6LfSKXskAAAAAKhfWvm0QjH_tQfwJQl9wBbfGOzK", {
          action: "submit",
        })
        .then(async () => {
          try {
            const email = formData.email;
            let url = `https://docs.google.com/forms/u/2/d/e/1FAIpQLSce5pjtBheygXf2S5Lb7OslBmnoKG9h5daHrQUMz2npiXY0xQ/formResponse?entry.1181170595=${email}`;
            await fetch(url, {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
            });
          } catch (_) {
            // Don't catch CORS error it will work anyway
          } finally {
            enqueueSnackbar(
              "Thank you for signing up! We will get back to you soon.",
              {
                persist: true,
                variant: "success",
              }
            );
            if (onSuccess) {
              onSuccess();
            }
            setState({ ...state, submitting: false });
            reset(defaultValues);
          }
        });
    });
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack
        spacing={1.5}
        sx={{ marginTop: 2 }}
        alignItems="center"
        alignContent="center"
        justifyContent="center"
        direction={{ xs: "column", md: "row" }}
      >
        <RHFTextField
          name="email"
          placeholder="Your company email"
          fullWidth={!isDesktop}
          sx={{
            "& .MuiOutlinedInput-root fieldset": {
              borderColor:
                colorMode === "light" ? "common.white" : "common.black",
              backgroundColor: (theme) => theme.palette.common.white,
            },
            "& .MuiOutlinedInput-root:hover fieldset": {
              borderColor:
                colorMode === "light" ? "common.white" : "common.black",
              backgroundColor: (theme) => theme.palette.common.white,
            },
            "& .MuiOutlinedInput-input": {
              color: "text.dark",
              zIndex: 1,
            },

            minWidth: "350px",
          }}
        />
        <LoadingButton
          type="submit"
          color="inherit"
          size="large"
          variant="contained"
          loading={state.submitting}
          sx={{
            borderColor: "common.white",
            whiteSpace: "nowrap",
            minWidth: "max-content",
            height: "56px",
            background:
              colorMode === "light"
                ? theme.palette.common.white
                : theme.palette.common.black,
            color:
              colorMode === "light"
                ? theme.palette.common.black
                : theme.palette.common.white,
            "&:hover": {
              backgroundColor:
                colorMode === "light"
                  ? theme.palette.grey[300]
                  : theme.palette.grey[900],
              "@media (hover: none)": {
                backgroundColor: "transparent",
              },
            },
          }}
          fullWidth={!isDesktop}
        >
          Book a demo
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
