import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSnackbar } from '../snackbar';

Updater.propTypes = {
  newVersion: PropTypes.bool.isRequired,
  autoAccept: PropTypes.bool,
  onAccept: PropTypes.func.isRequired,
};

export default function Updater({ newVersion, autoAccept = false, onAccept }) {

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (newVersion) {
      if (autoAccept) {
        onAccept();
      } else {
        enqueueSnackbar('A new version was released', {
          persist: true,
          variant: 'success',
          action: <Button
            className="snackbar-button"
            size="small"
            onClick={onAccept}
          >
            Update
          </Button>,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newVersion]);

  return null;
}
