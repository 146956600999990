const componentsOverride = (theme) => ({
  MuiContainer: {
    styleOverrides: {
      root: {
        [theme.breakpoints.up("lg")]: {
          maxWidth: "1320px",
          paddingLeft: "32px",
          paddingRight: "32px",
        },
      },
    },
  },
});

export default componentsOverride;
