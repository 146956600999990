import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import CompactLayout from '../layouts/compact';
import MainLayout from '../layouts/main';
//
import {
  Page404,
  HomePage,
  AboutPage,
  Contact,
  Signup,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Main Routes
    {
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: '/about-us', element: <AboutPage />, index: true },
        { path: '/contact-us', element: <Contact />, index: true },
        { path: '/signup', element: <Signup />, index: true },
      ]
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
