import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { m } from "framer-motion";

// @mui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  AppBar,
  Toolbar,
  Container,
  Dialog,
  DialogContent,
  Typography,
  IconButton,
} from "@mui/material";
import HomeSignupForm from "../../sections/home/HomeSignupForm";
import { varFade } from "../../components/animate";
import Iconify from "../../components/iconify";

// hooks
import useOffSetTop from "../../hooks/useOffSetTop";
import useResponsive from "../../hooks/useResponsive";
// config
import { HEADER } from "../../config-global";
// components
import Logo from "../../components/logo";

//
import navConfig from "./nav/config-navigation";
import NavMobile from "./nav/mobile";
import NavDesktop from "./nav/desktop";

// ----------------------------------------------------------------------

export default function Header() {
  const carouselRef = useRef(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const theme = useTheme();

  const isDesktop = useResponsive("up", "md");

  const isOffset = useOffSetTop(isDesktop ? HEADER.H_MAIN_DESKTOP : 0);

  return (
    <AppBar
      ref={carouselRef}
      color="transparent"
      sx={{ boxShadow: 0, zIndex: theme.zIndex.appBar }}
    >
      <Toolbar
        disableGutters
        sx={{
          background: theme.palette.common.white,
          height: {
            xs: HEADER.H_MOBILE - 10,
            lg: HEADER.H_MAIN_DESKTOP - 16,
            xl: HEADER.H_MAIN_DESKTOP,
          },
        }}
      >
        <Container
          sx={{ height: 1, display: "flex", alignItems: "center" }}
          maxWidth="xl"
        >
          <Logo
            sx={{ width: { xs: 100, md: 188 } }}
            color={theme.palette.common.black}
            variant="imagotype"
          />

          <Box sx={{ flexGrow: 1 }} />

          {isDesktop && <NavDesktop isOffset={isOffset} data={navConfig} />}

          <Button
            size={isDesktop ? "medium" : "small"}
            variant="outlined"
            sx={{ height: 40, borderColor: "common.black" }}
            color="inherit"
            onClick={handleOpenDialog}
          >
            Book a demo
          </Button>

          {!isDesktop && <NavMobile isOffset={isOffset} data={navConfig} />}
        </Container>
      </Toolbar>

      {isOffset && <Shadow />}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            zIndex: 1,
          }}
        >
          <Iconify icon={"eva:close-fill"} width={24} />
        </IconButton>

        <DialogContent
          sx={{
            background: "white",
            p: { xs: 2, sm: 4, md: 8 },
            position: "relative",
          }}
        >
          <img
            alt="demo"
            src="assets/illustrations/illustration_demo.svg"
            style={{
              marginY: "32px",
              width: "100%",
            }}
          />
          <Box sx={{ textAlign: "center" }}>
            <Box
              component={m.div}
              variants={varFade().inDown}
              sx={{
                mt: 2,
                color: "text.dark",
                typography: "h3",
                fontWeight: 300,
                display: "inline",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Get started on{" "}
              <Logo
                sx={{
                  position: "relative",
                  top: 12,
                  mx: 1,
                  width: 98,
                  height: "inherit",
                }}
                color={theme.palette.common.black}
              />
              <Typography
                variant="h3"
                component="span"
                sx={{
                  fontWeight: 300,
                  display: "inline",
                }}
              >
                today!
              </Typography>
            </Box>
          </Box>

          <Box
            component={m.div}
            variants={varFade().inDown}
            sx={{
              color: "text.dark",
              mb: 3,
              ml: 0.5,
              typography: "body",
              textAlign: "center",
            }}
          >
            Let our team guide you through the full functionality
          </Box>
          <m.div variants={varFade().inRight}>
            <HomeSignupForm colorMode="dark" onSuccess={handleCloseDialog} />
          </m.div>
        </DialogContent>
      </Dialog>
    </AppBar>
  );
}

// ----------------------------------------------------------------------

Shadow.propTypes = {
  sx: PropTypes.object,
};

function Shadow({ sx, ...other }) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: "auto",
        borderRadius: "50%",
        position: "absolute",
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
