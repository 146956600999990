import PropTypes from "prop-types";
// @mui
import { Stack } from "@mui/material";
//
import NavList from "./NavList";

// ----------------------------------------------------------------------

NavDesktop.propTypes = {
  data: PropTypes.array,
  isOffset: PropTypes.bool,
};

export default function NavDesktop({ isOffset, data }) {
  return (
    <Stack
      component="nav"
      direction="row"
      spacing={6}
      sx={{ mr: 5, height: 1, alignItems: "center" }}
    >
      {data.map((link) => (
        <NavList key={link.title} item={link} isOffset={isOffset} />
      ))}
    </Stack>
  );
}
