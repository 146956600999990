// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: "/login",
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  signup: "/signup",
  faqs: "/faqs",
  page403: "/403",
  page404: "/404",
  page500: "/500",
};

export const EXTERNAL_PATH_PAGE = {
  docs: "https://docs.nullplatform.com/",
};
