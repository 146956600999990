// @mui
import {
  Box,
  Grid,
  Stack,
  Container,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
// components
import Image from "../../components/image";
import Logo from "../../components/logo";
import Iconify from "../../components/iconify";
import { format } from "date-fns";

// ----------------------------------------------------------------------

const socials = Object.freeze([
  {
    value: "linkedin",
    name: "Linkedin",
    icon: "bi:linkedin",
    color: "#007EBB",
    path: "https://www.linkedin.com/company/nullplatform",
  },
  {
    value: "twitter",
    name: "Twitter",
    icon: "line-md:twitter-x",
    color: "#00AAEC",
    path: "https://twitter.com/nullplatform",
  },
  {
    value: "medium",
    name: "Medium",
    icon: "mingcute:medium-fill",
    color: "#00AAEC",
    path: "https://medium.com/nullplatform",
  },
]);

// ----------------------------------------------------------------------

export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const currentYear = format(new Date(), "yyyy");

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: "relative",
        bgcolor: "common.black",
        borderRadius: (theme) =>
          `${theme.shape.borderRadius * 4}px ${
            theme.shape.borderRadius * 4
          }px 0 0`,
      }}
    >
      <Container sx={{ py: 4 }}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box display={"flex"} gap={2}>
              <Logo color="white" />
              <Typography variant="caption" component="div" color="text.white">
                © {currentYear}. All rights reserved <br />
                Nullplatform Technologies LLC
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{ mt: { xs: 2, sm: 0 } }}
            >
              <Stack
                spacing={1}
                direction="row"
                justifyContent={{ xs: "flex-start", sm: "flex-end" }}
              >
                {socials.map((social) => (
                  <Tooltip title={social.name} arrow>
                    <IconButton
                      key={social.name}
                      target="_blank"
                      rel="noopener"
                      href={social.path}
                    >
                      <Iconify icon={social.icon} color="common.white" />
                    </IconButton>
                  </Tooltip>
                ))}
              </Stack>
              <Stack
                spacing={1}
                direction="row"
                justifyContent={"flex-end"}
                mt={2}
              >
                <Tooltip title="Go to top" arrow>
                  <IconButton onClick={scrollToTop} aria-label="Scroll to top">
                    <Image
                      src={"/assets/icons/footer/scroll.svg"}
                      alt={"go to top"}
                      sx={{ minWidth: 50, height: 50 }}
                    />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

  return mainFooter;
}
