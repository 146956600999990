import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const LogoVariant = Object.freeze({
  ISOTYPE: 'isotype',
  IMAGOTYPE_SHORT: 'imagotype-short',
  IMAGOTYPE: 'imagotype',
});

// eslint-disable-next-line no-unused-vars
const Logo = forwardRef(({ disabledLink = false, variant, color, sx, ...other }, ref) => {
  const { palette } = useTheme();
  const fillColor = color || (palette.mode === 'light' ? palette.common.black : palette.common.white);

  const isotype =
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 339.18 345.63">
        <path fill={fillColor} d="m299.72,0l-104.03,188.43c-2.29,4.15-8.6,2.52-8.6-2.22v-62.17c0-30.74-30.48-35.7-46.38-36.74h-.02s-32.29,0-32.29,0c-36.17,0-49.67,24.28-53.18,32.45-.22.5-.97.33-.96-.21l.64-30.63c0-.28-.22-.51-.5-.51l-53.9.2c-.28,0-.5.22-.5.5v147.27c0,.28.22.5.5.5h54.5c.28,0,.5-.22.5-.5v-75.85c0-18.02,25.18-16.46,25.79-16.42h.03s29.69,0,29.69,0c22.93,0,22.67,17.72,22.67,17.72v74.54c0,.28.22.5.5.5h34.78l-60.04,108.75h39.46L339.18,0h-39.46Z"/>
      </svg>;

  const imagotypeShort =
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 556.48 271.97">
        <path fill={fillColor} d="m526.22,0l-90.11,163.23v-.08c-10.05,1.85-12.51-.82-12.51-21.94V58.36l-46.34-.2v113.2c0,18.04,5.95,36.29,33.01,36.29h1.32l-35.52,64.33h30.26L556.48,0h-30.26Z"/>
        <path fill={fillColor} d="m110.74,89.95s-.02,0-.03,0h-25.41c-28.46,0-39.08,19.1-41.85,25.53-.17.4-.76.26-.75-.17l.5-24.1c0-.22-.17-.4-.39-.4l-42.41.16c-.22,0-.39.18-.39.39v115.89c0,.22.18.39.39.39h42.89c.22,0,.39-.18.39-.39v-59.68c0-14.21,19.88-12.95,20.29-12.92,0,0,.01,0,.02,0h23.36c18.04,0,17.84,13.94,17.84,13.94v58.66c0,.22.18.39.39.39h41.25c.22,0,.39-.18.39-.39v-88.39c0-24.19-23.97-28.09-36.48-28.91Z"/>
        <path fill={fillColor} d="m259.35,150.81c0,14.35-20.3,12.92-20.3,12.92h-23.38c-18.04,0-17.84-13.94-17.84-13.94v-59.05h-42.03v88.78c0,24.2,23.99,28.09,36.5,28.91h25.43c28.46,0,39.08-19.1,41.85-25.53.17-.4.76-.26.75.17l.06,24.2,42.64.14v-116.67h-43.68v60.08Z"/>
        <path fill={fillColor} d="m357.78,141.2V58.36l-46.34-.2v113.2c0,18.04,5.95,36.29,33.01,36.29h25.84v-44.49c-10.05,1.85-12.51-.82-12.51-21.94Z"/>
      </svg>;

  const imagotype =
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1284.52 271.97">
        <path fill={fillColor} d="m549.08,106.37l-18.25,18.25v-18.25h-14.76v145.57h15.17v-58.23l16.81,14.76h39.37l18.86-18.86v-64.38l-18.86-18.86h-38.34Zm42.03,78.53l-10.25,10.05h-29.94l-19.68-17.84v-35.27l21.94-21.94h27.68l10.25,10.05v54.95Z"/>
        <rect fill={fillColor} x="636.01" y="62.08" width="15.17" height="146.39"/>
        <path fill={fillColor} d="m699.57,106.37l-18.86,18.86v12.71h15.17v-8.41l10.25-10.05h34.86l10.25,10.05v19.48h-54.54l-16.81,16.61v26.04l16.81,16.81h36.09l18.86-18.04v18.04h14.56v-83.24l-18.86-18.86h-47.77Zm51.67,68.28l-21.53,20.71h-26.45l-8.2-8v-17.22l8.2-8h47.98v12.51Z"/>
        <polygon fill={fillColor} points="818.28 73.15 803.52 73.15 803.52 106.37 784.65 106.37 784.65 119.69 803.11 119.69 803.11 189.61 821.97 208.47 850.27 208.47 850.27 195.14 828.53 195.14 818.28 184.89 818.28 119.69 850.27 119.69 850.27 106.37 818.28 106.37 818.28 73.15"/>
        <polygon fill={fillColor} points="883.07 83.61 883.07 106.37 864.62 106.37 864.62 119.69 883.07 119.69 883.07 208.47 898.24 208.47 898.24 119.69 930.43 119.69 930.43 106.37 898.24 106.37 898.24 87.91 907.47 78.89 930.43 78.89 930.43 65.77 900.91 65.77 883.07 83.61"/>
        <path fill={fillColor} d="m963.65,106.37l-18.86,18.86v64.38l18.86,18.86h52.49l18.86-18.86v-64.38l-18.86-18.86h-52.49Zm56.18,78.53l-10.25,10.05h-39.37l-10.25-10.05v-54.95l10.25-10.05h39.37l10.25,10.05v54.95Z"/>
        <polygon fill={fillColor} points="1078.46 124.61 1078.46 106.37 1063.7 106.37 1063.7 208.47 1078.87 208.47 1078.87 141.63 1100.61 119.9 1123.16 119.9 1123.16 106.37 1096.71 106.37 1078.46 124.61"/>
        <polygon fill={fillColor} points="1265.66 106.37 1233.47 106.37 1217.48 122.36 1201.48 106.37 1177.08 106.37 1157.4 126.05 1157.4 106.37 1142.64 106.37 1142.64 208.47 1157.81 208.47 1157.81 142.04 1179.95 119.9 1197.18 119.9 1207.43 129.94 1207.43 208.47 1222.19 208.47 1222.19 134.25 1236.54 119.9 1259.1 119.9 1269.35 129.94 1269.35 208.47 1284.52 208.47 1284.52 125.23 1265.66 106.37"/>
        <path fill={fillColor} d="m526.22,0l-90.11,163.23v-.08c-10.05,1.85-12.51-.82-12.51-21.94V58.36l-46.34-.2v113.2c0,18.04,5.95,36.29,33.01,36.29h1.32l-35.52,64.33h30.26L556.48,0h-30.26Z"/>
        <path fill={fillColor} d="m110.74,89.95s-.02,0-.03,0h-25.41c-28.46,0-39.08,19.1-41.85,25.53-.17.4-.76.26-.75-.17l.5-24.1c0-.22-.17-.4-.4-.4l-42.42.16c-.22,0-.39.18-.39.39v115.89c0,.22.18.39.39.39h42.89c.22,0,.39-.18.39-.39v-59.68c0-14.21,19.88-12.94,20.29-12.92,0,0,.01,0,.02,0h23.36c18.04,0,17.84,13.94,17.84,13.94v58.66c0,.22.18.39.39.39h41.25c.22,0,.39-.18.39-.39v-88.39c0-24.19-23.97-28.09-36.48-28.91Z"/>
        <path fill={fillColor} d="m259.35,150.81c0,14.35-20.3,12.92-20.3,12.92h-23.38c-18.04,0-17.84-13.94-17.84-13.94v-59.05h-42.03v88.78c0,24.2,23.99,28.09,36.5,28.91h25.43c28.46,0,39.08-19.1,41.85-25.53.17-.4.76-.26.75.17l.06,24.2,42.64.14v-116.67h-43.68v60.08Z"/>
        <path fill={fillColor} d="m357.78,141.2V58.36l-46.34-.2v113.2c0,18.04,5.95,36.29,33.01,36.29h25.84v-44.49c-10.05,1.85-12.51-.82-12.51-21.94Z"/>
      </svg>;

  const logoVariant = () => {
    switch(variant) {
      case LogoVariant.ISOTYPE:
        return isotype;
      case LogoVariant.IMAGOTYPE:
        return imagotype;
      case LogoVariant.IMAGOTYPE_SHORT:
        return imagotypeShort;
      default:
        return imagotypeShort;
    }
  }

  const logo = (
      <Box
          ref={ref}
          component="div"
          sx={{
            width: 60,
            height: 40,
            display: 'inline-flex',
            ...sx,
          }}
          {...other}
      >
        { logoVariant() }
      </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
      <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
        {logo}
      </Link>
  );
});

Logo.propTypes = {
    sx: PropTypes.object,
    disabledLink: PropTypes.bool,
    color: PropTypes.string,
    variant: PropTypes.oneOf([LogoVariant.ISOTYPE, LogoVariant.IMAGOTYPE, LogoVariant.IMAGOTYPE_SHORT]),
};

export default Logo;
