// i18n
import "./locales/i18n";

// scroll bar
import "simplebar-react/dist/simplebar.min.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";

// ----------------------------------------------------------------------

import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga4";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// locales
import ThemeLocalization from "./locales";
// hooks
import useInterval from "./hooks/useInterval";
// components
import SnackbarProvider from "./components/snackbar";
import { ThemeSettings, SettingsProvider } from "./components/settings";
import { MotionLazyContainer } from "./components/animate";
import ScrollToTop from "./components/scroll-to-top";
import Updater from "./components/updater";
//
import { register, update } from "./serviceWorkerRegistration";

// ----------------------------------------------------------------------

// 5 minutes
const POLLING_INTERVAL = 300000;
const TRACKING_ID = "G-3HCE8Q0NSR";
ReactGA.initialize(TRACKING_ID);

export default function App() {
  const [version, setVersion] = useState({
    newVersionAvailable: false,
    waitingWorker: null,
  });

  const trackPageViews = () => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  };

  const onUpdate = (registration) => {
    setVersion({
      newVersionAvailable: true,
      waitingWorker: registration.waiting,
    });
  };

  const onAcceptUpdate = () => {
    const { waitingWorker } = version;
    if (waitingWorker) {
      waitingWorker.postMessage({ type: "SKIP_WAITING" });
      setVersion({ newVersionAvailable: false, waitingWorker: null });
      window.location.reload();
    }
  };

  useEffect(() => {
    register({ onUpdate });
    trackPageViews();
  }, []);

  useInterval(async () => {
    update();
  }, POLLING_INTERVAL);

  return (
    <HelmetProvider>
      <SettingsProvider>
        <BrowserRouter>
          <ScrollToTop />
          <MotionLazyContainer>
            <ThemeProvider>
              <ThemeSettings>
                <ThemeLocalization>
                  <SnackbarProvider>
                    <Updater
                      newVersion={version.newVersionAvailable}
                      onAccept={onAcceptUpdate}
                      autoAccept
                    />
                    <Router />
                  </SnackbarProvider>
                </ThemeLocalization>
              </ThemeSettings>
            </ThemeProvider>
          </MotionLazyContainer>
        </BrowserRouter>
      </SettingsProvider>
    </HelmetProvider>
  );
}
